<template>
    <div class="venn-diagram" v-observe-visibility="visibilityChanged" :class="`venn-${selected}`">

        <div class="inner">
            <div class="circles">
                <div class="arrow arrow-top-left"></div>
                <div class="arrow arrow-top-right"></div>
                <div class="arrow arrow-bottom-right"></div>

                <div class="circle outline top-left-circle-outline" @click.prevent="handleTopLeftClicked"></div>
                <div class="circle outline top-right-circle-outline" @click.prevent="handleTopRightClicked"></div>
                <div class="circle outline bottom-circle-outline" @click.prevent="handleBottomClicked"></div>

                <div class="circle top-left-circle hidden" @click.prevent="handleTopLeftClicked"></div>
                <div class="circle top-right-circle hidden" @click.prevent="handleTopRightClicked"></div>
                <div class="circle bottom-circle hidden" @click.prevent="handleBottomClicked"></div>

                <div class="circle hover top-left-hover hidden" @click.prevent="handleTopLeftClicked"></div>
                <div class="circle hover top-right-hover hidden" @click.prevent="handleTopRightClicked"></div>
                <div class="circle hover bottom-hover hidden" @click.prevent="handleBottomClicked"></div>

                <div class="center" @click.prevent="handleCenterClicked"></div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['selected'],

    mounted() {
        let vm = this;
        let prefix = '.venn-' + this.selected + ' ';

        const top_left_circle_outline = document.querySelector(prefix + '.top-left-circle-outline');
        const top_left_circle = document.querySelector(prefix + '.top-left-circle');
        const top_left_hover = document.querySelector(prefix + '.top-left-hover');

        const top_right_circle_outline = document.querySelector(prefix + '.top-right-circle-outline');
        const top_right_circle = document.querySelector(prefix + '.top-right-circle');
        const top_right_hover = document.querySelector(prefix + '.top-right-hover');

        const bottom_circle_outline = document.querySelector(prefix + '.bottom-circle-outline');
        const bottom_circle = document.querySelector(prefix + '.bottom-circle');
        const bottom_hover = document.querySelector(prefix + '.bottom-hover');

        const center = document.querySelector(prefix + '.center');

        vm.restoreInitialState();

        top_left_circle_outline.addEventListener('mouseover', (ev) => {
            vm.removeAllVisibles();
            top_left_hover.classList.remove('hidden');
            top_right_circle.classList.remove('hidden');
            bottom_circle.classList.remove('hidden');
        });

        top_left_circle_outline.addEventListener('mouseout', (ev) => {
            top_left_hover.classList.add('hidden');
            top_right_circle.classList.add('hidden');
            bottom_circle.classList.add('hidden');
            vm.restoreInitialState();
        });

        top_right_circle_outline.addEventListener('mouseover', (ev) => {
            vm.removeAllVisibles();
            top_right_hover.classList.remove('hidden');
            top_left_circle.classList.remove('hidden');
            bottom_circle.classList.remove('hidden');
        });

        top_right_circle_outline.addEventListener('mouseout', (ev) => {
            top_right_hover.classList.add('hidden');
            top_left_circle.classList.add('hidden');
            bottom_circle.classList.add('hidden');
            vm.restoreInitialState();
        });

        bottom_circle_outline.addEventListener('mouseover', (ev) => {
            vm.removeAllVisibles();
            bottom_hover.classList.remove('hidden');
            top_left_circle.classList.remove('hidden');
            top_right_circle.classList.remove('hidden');
        });

        bottom_circle_outline.addEventListener('mouseout', (ev) => {
            bottom_hover.classList.add('hidden');
            top_left_circle.classList.add('hidden');
            top_right_circle.classList.add('hidden');
            vm.restoreInitialState();
        });

        center.addEventListener('mouseover', (ev) => {
            vm.removeAllVisibles();
            top_left_circle.classList.add('hidden');
            top_right_circle.classList.add('hidden');
            bottom_circle.classList.add('hidden');

            top_left_hover.classList.add('hidden');
            top_right_hover.classList.add('hidden');
            bottom_hover.classList.add('hidden');
        });

        // Make animate in on page load for commercial intelligence
        if (this.selected == 'ci') {
            this.visibilityChanged(true);
        }
    },

    methods: {
        handleTopLeftClicked() {
            const challenge = document.querySelector('#challenge');
            challenge.scrollIntoView({ behavior: 'smooth' });
        },

        handleTopRightClicked() {
            const research = document.querySelector('#research');
            research.scrollIntoView({ behavior: 'smooth' });
        },

        handleBottomClicked() {
            const simple = document.querySelector('#simple');
            simple.scrollIntoView({ behavior: 'smooth' });
        },

        handleCenterClicked() {
            const commercialIntelligence = document.querySelector('#commercial-intelligence');
            commercialIntelligence.scrollIntoView({ behavior: 'smooth' });
        },

        restoreInitialState() {
            let prefix = '.venn-' + this.selected + ' ';

            const top_left_circle_outline = document.querySelector(prefix + '.top-left-circle-outline');
            const top_left_circle = document.querySelector(prefix + '.top-left-circle');
            const top_left_hover = document.querySelector(prefix + '.top-left-hover');

            const top_right_circle_outline = document.querySelector(prefix + '.top-right-circle-outline');
            const top_right_circle = document.querySelector(prefix + '.top-right-circle');
            const top_right_hover = document.querySelector(prefix + '.top-right-hover');

            const bottom_circle_outline = document.querySelector(prefix + '.bottom-circle-outline');
            const bottom_circle = document.querySelector(prefix + '.bottom-circle');
            const bottom_hover = document.querySelector(prefix + '.bottom-hover');

            const center = document.querySelector(prefix + '.center');

            if (this.selected == 'ci') {
                center.classList.add('visible');
            }

            if (this.selected == 'challenge') {
                top_left_hover.classList.add('visible');
                top_right_circle.classList.add('visible');
                bottom_circle.classList.add('visible');
            }

            if (this.selected == 'research') {
                top_right_hover.classList.add('visible');
                top_left_circle.classList.add('visible');
                bottom_circle.classList.add('visible');
            }

            if (this.selected == 'simple') {
                bottom_hover.classList.add('visible');
                top_left_circle.classList.add('visible');
                top_right_circle.classList.add('visible');
            }
        },

        removeAllVisibles() {
            let prefix = '.venn-' + this.selected + ' ';
            const visibles = document.querySelectorAll(prefix + '.visible');
            visibles.forEach((visible) => {
                visible.classList.remove('visible');
            });
        },

        visibilityChanged(isVisible, entry) {
            let prefix = '.venn-' + this.selected + ' ';
            let classes = ['.circle', '.arrow', '.center'];

            if (isVisible) {
                classes.forEach((className) => {
                    const elements = document.querySelectorAll(prefix + className);
                    elements.forEach((element) => {
                        element.classList.add('animated-in');
                    });
                });
            } else {
                // Don't animate out for commercial intelligence
                // if (this.selected == 'ci') {
                //     return false;
                // }

                classes.forEach((className) => {
                    const elements = document.querySelectorAll(prefix + className);
                    elements.forEach((element) => {
                        element.classList.remove('animated-in');
                    });
                });
            }
        }
    }
}
</script>

<style></style>