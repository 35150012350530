<template>
    <div class="legal-page">
        <div class="page-header">
            <div class="ragdoll-container">
                <h1 v-if="legalPage">{{ legalPage.post_title }}</h1>
            </div>
        </div>

        <div class="page-content">
            <blocks></blocks>
            <!-- <div class="blocks">
                <div class="block">
                    <div class="block-inner block-inner-has-actions">
                        <div class="block-inner-top">
                            <div class="two-columns">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe sint modi fugiat adipisci ipsam officia explicabo quia minima voluptatibus accusamus qui cum quo, dignissimos rerum, repudiandae laudantium et harum odit!
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores aperiam accusamus tempore recusandae repellendus consequuntur placeat dicta cum eius rerum ex, exercitationem dolorum totam blanditiis ducimus nostrum facere impedit molestiae?
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat atque praesentium commodi harum fuga nesciunt. Consectetur dicta nostrum quibusdam aut, aspernatur culpa odio facere? Explicabo rem cum ipsum corrupti eos.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi accusantium modi quisquam dignissimos, voluptates earum vitae enim neque expedita quidem veritatis optio voluptatum aperiam dolorum dicta quam eaque architecto? Recusandae?
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe, repellat inventore, exercitationem cum recusandae porro earum iusto minus optio non nemo fuga deleniti provident reprehenderit et amet rem quidem nulla?
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente fugit eos pariatur sunt sed officia, suscipit eligendi ratione eveniet officiis possimus deleniti dolor atque alias ipsam facilis laboriosam facere mollitia.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem ipsa atque itaque ex alias illo quod. Cum omnis minus doloremque, exercitationem facere sit. Quod veniam minima vel unde, officiis suscipit.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, libero magni fuga ratione ullam optio tenetur aliquid sequi consequuntur, facilis ad pariatur voluptates, maxime quasi molestiae rem minima eligendi repudiandae.
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni ex laborum molestias amet! Voluptatibus nulla nisi non suscipit labore qui nesciunt obcaecati sint porro. Corporis saepe deleniti assumenda eaque amet?
                            </div>
                        </div>
                        <div class="block-inner-bottom actions">
                            <a href="#research" class="action-btn btn-next">
                                Continue
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // legalPage: {
            // }
            legalPage: null
        }
    },
    mounted() {
        this.legalPage = window.legalPage;
    }
}
</script>

<style></style>