<template>
    <!-- Block -->
    <div class="block flex justify-content-center" id="block-1">
        <video class="fullscreen-video" autoplay muted loop id="fullscreenVideo">
            <source src="/video/homepage.mp4" type="video/mp4">
        </video>
        <!-- <h1 class="center-title">making the complicated <span>simple</span></h1> -->
        <h1 class="center-title" v-html="block.title"></h1>
        <div class="slide-right-button">
            <a :href="`#${block.next_button_destination}`" class="action-btn"></a>
        </div>
    </div>
    <!-- / Block -->
</template>

<script>
export default {
    props: ['block']
}
</script>

<style></style>