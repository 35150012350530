<template>
  <div class="ragdoll-container thoughts">
    <div class="left">

      <div class="top">
        <!-- Search Box -->
        <div class="search">
          <input placeholder="Search" v-model="searchQuery" @keyup.enter="search" type="text" class="search-input">
        </div>
        <!-- / Search Box -->

        <!-- Category Toggle - Mobile -->
        <a href="#" class="category-toggle" @click.prevent="showCategories = !showCategories">
          Categories
        </a>
        <!-- / Category Toggle - Mobile -->
      </div>

      <!-- Categories -->
      <div class="categories" :class="{ 'hidden': !showCategories }">
        <div class="category">
          <div class="title">
            Categories:
          </div>
        </div>
        <div class="category">
          <a href="/thoughts">All Posts</a>
        </div>
        <div v-for="(category, i) in categories" :key="`category-${i}`" class="category">
          <a :href="`/category/${category.slug}`">{{ category.name }}</a>
        </div>
        <!-- <div class="category">
                  <a href="#">Ethnographic Research</a>
              </div>
              <div class="category">
                  <a href="#">Focus Groups</a>
              </div>
              <div class="category">
                  <a href="#">General</a>
              </div> -->
      </div>
      <!-- / Categories -->

    </div>
    <div class="right">

      <div class="posts">

        <a :href="`/thought/${post.slug}`" class="post" :class="{ 'only-post': onlyOnePost }" 
          v-for="(post, i) in posts" :key="`post-${i}`">

          <div class="title">
            <div>
              <div>
                {{ post.post_title }} 
              </div>
              <div class="read-more">Read More</div>
            </div>

          </div>
          <div class="image">
            <div class="image-inner" :style="getPostImageStyle(post)"></div>
          </div>
        </a>

      </div>

    </div>
  </div>
</template>>
<script>
export default {
  components: {},
  data() {
    return {
      categories: window.categories,
      posts: window.posts,
      postThumbnails: window.postThumbnails,

      searchQuery: '',
      showCategories: false
    }
  },
  computed: {
    onlyOnePost() {
      // Check if posts is object, or array of length 1
      if (typeof this.posts === 'object') {
        return Object.keys(this.posts).length === 1;
      } else {
        return this.posts.length === 1;
      }
    }
  },
  methods: {
    getPostImageStyle(post) {
      // Check if the post has a thumbnail
      if (this.postThumbnails[post.ID]) {
        return {
          'background': `linear-gradient(0deg, rgba(29, 29, 27, 0.3), rgba(29, 29, 27, 0.3)), url(${this.postThumbnails[post.ID]})`,
          'background-size': 'cover',
          'background-position': 'center'
        }
      }

      // Otherwise, return a default background image
      return {
        'display': 'none'
      };
    },

    search() {
      // Redirect to current page, but with search query set to searchQuery, accounting for other parameters too
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('s', this.searchQuery);
      window.location.href = `${window.location.pathname}?${urlParams.toString()}`;
    }
  },
  mounted() {
    this.categories = window.categories;
    this.posts = window.posts;
    this.postThumbnails = window.postThumbnails;

    // Set searchQuery from s param
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('s');
    if (searchQuery) {
      this.searchQuery = searchQuery;
    }
  }
}
</script>