<template>
    <div class="career">
        <div class="ragdoll-container">
            <div class="career-inner">

                <!-- Page Header -->
                <div class="page-header">
                    <h1>{{ career.custom.title }}</h1>

                    <p class="subtitle">
                        {{ career.custom.subtitle }}
                    </p>
                </div>
                <!-- / Page Header -->

                <!-- Row -->
                <div class="row">
                    <div class="left">
                        <div class="section">
                            <h2>The Role</h2>
                            <p>
                                {{ career.custom.the_role }}
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="section">
                            <h2>The Ideal Candidate</h2>
                            <p>
                                {{ career.custom.the_ideal_candidate }}
                            </p>
                        </div>

                        <div class="section sound-like-you">
                            <h2>Sound Like You?</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </p>
                            <a href="/careers" class="action-btn btn-next apply-button">Apply </a>
                        </div>
                    </div>
                </div>
                <!-- / Row -->

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            career: window.career
        }
    },
    mounted() {
        this.career = window.career;
    },
    components: {},
    setup() {
    }
}
</script>

<style scoped lang="scss"></style>