<template>
    <div class="ragdoll-container thought">

        <div class="left">

            <div class="top-details">
                <div class="detail">
                    <b>Author:</b> {{ author ? author : 'Unknown' }}
                </div>
                <!-- <div class="detail">
                    <b>Date:</b> {{ formatDate(post.post_date) }}
                </div> -->
            </div>

            <div class="page-title">
                <h1>
                    {{ post.post_title }}
                </h1>
            </div>

            <div class="content">
                <div v-html="post.post_content"></div> 
            </div>

        </div>

        <div class="right" v-if="thumbnail" :style="{'background-image': 'url(' + thumbnail + ')'}">
        </div>

    </div>
</template>>
<script>
import moment from 'moment';
export default {
    components: {},
    data() {
        return {
            post: window.post,
            thumbnail: window.thumbnail,
            author: window.author
        }
    },
    mounted() {
        this.post = window.post;
        this.thumbnail = window.thumbnail;
        this.author = window.author;
    },
    methods: {
        formatDate(datetime) {
            return moment(datetime).format('Do MMMM YYYY');
        }
    },
    mounted() {
    }
}
</script>