<template>
  <div class="ragdoll-container page-footer"  :class="{'floating': isFloating}">
    <div class="left">
        © Ragdoll Research Ltd. 2023. All rights reserved. <a href="/terms-and-conditions">Terms and conditions.</a> <a href="/privacy-policy">Privacy policy.</a> <a href="/cookie-policy">Cookie policy.</a> <a href="/faqs">FAQs.</a> <span class="tel-number">Call us on 01179102800.</span>
    </div>
    <div class="right">
      <!-- <a href="#">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/facebook.svg" alt="Facebook">
      </a> -->
      <a target="_blank" href="https://www.linkedin.com/company/ragdoll-research-&-planning/about/?viewAsMember=true">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/linkedin.png" alt="LinkedIn">
      </a>
      <a target="_blank" href="https://twitter.com/ragdollresearch?lang=en">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/twitter.svg" alt="Twitter">
      </a>
      <!-- <img src="/img/fsqs.png" class="helios" alt=""> -->
      <img src="/wp-content/themes/timber-starter-theme/assets/img/fsqs.png" class="helios" alt="">
      <!-- <a href="#">
        <img src="/wp-content/themes/timber-starter-theme/assets/img/social/instagram.svg" alt="Instagram">
      </a> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['isFloating'],

  computed: {
    // isFloating() {
      // return this.$route.name == 'home';
    // }
  }
}
</script>

<style>

</style>