<template>
  <div class="page-wrapper">
    <page-navigation></page-navigation>
    <div class="page-content">
      <router-view />
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageNavigation from './components/PageNavigation.vue'
import PageFooter from './components/PageFooter.vue';
export default {
  components: { PageNavigation, PageFooter } 
}
</script>

<style lang="scss"></style>
